<template>
  <v-container fluid>
    <v-row class="mt-5 mb-15" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Update Product</strong></small>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field dense outlined v-model="selected_product.name" label="Name">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-autocomplete v-model="selected_product.supplier_product_type_id" label="Activity Type"
                prepend-inner-icon="mdi-tooltip-text-outline" :items="get_supplier_product_type" item-value="id"
                item-text="name" outlined dense>
              </v-autocomplete>
            </v-col>
            <v-col cols="6">
              <v-select dense outlined v-model="selected_product.use_product_type_image" label="Displayed Image"
                :items="[{ text: 'Uploaded Product Image', id: 0 }, { text: 'Default Image for Activity Type', id: 1 }]"
                item-value="id" item-text="text"></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined type="number" v-model="selected_product.customer_price"
                :label="'Customer Price ' + get_selected_company.currency"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field dense outlined type="number" v-model="selected_product.supplier_price"
                :label="'Supplier Price ' + get_selected_company.currency"></v-text-field>
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Description</label>
              <wysiwyg dense outlined v-model="selected_product.description" label="Description" />
            </v-col>
            <v-col cols="6">
              <label style="font-weight:700;">Admin Notes</label>
              <wysiwyg dense outlined v-model="selected_product.admin_notes" label="Admin Notes" />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mb-10 mr-5">
          <v-spacer></v-spacer>
          <v-btn color="primary" rounded class="px-5" @click="updateProduct()">Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {},
  props: [],
  data: () => ({
    f: {
      supplier_product_type_id: null,
      name: null,
      code: null,
      description: null,
    },
  }),
  mounted() {
    this.$store.dispatch("supplier/fetch_supplier_product_type")
    console.log(this.selected_product)
  },
  created() {
  },
  computed: {
    ...mapGetters({
      get_selected_company: "auth/get_selected_company",
      get_supplier_setup: "supplier/get_supplier_setup",
      get_supplier_product_type: "supplier/get_supplier_product_type",
      selected_product: "supplier/get_supplier_product_setup",
    }),
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit(
        "auth/setMessage",
        { show: true, message: message },
        { root: 1 }
      )
    },
    async updateProduct() {
      await this.$axios.post(`admin/suppliers/update_supplier_product/${this.selected_product.id}`, this.selected_product)
        .then(({ data }) => {
          if (data.response) {
            this.$toast.success(data.message)
          }
        })
    }
    // async update_supplier_product() {
    //   let payload = {
    //     name: this.selected_product.name,
    //     description: this.selected_product.description,
    //   }
    //   await this.$axios.patch(`api/suppliers/products/change_products/${this.selected_product.id}`, payload)
    //     .then(({ data }) => {
    //       if (data.response) {
    //         this.$store.dispatch("supplier/set_supplier_product", data.data.list)
    //         this.$store.dispatch("supplier/set_supplier_product_setup", data.data.update)
    //       }
    //     });
    // },
  },
  watch: {},
};
</script>
<style scoped lang="scss"></style>
