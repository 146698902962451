<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Media</strong></small>
          <v-spacer />
          <v-btn color="#588BAD" rounded small class="pl-10 pr-10" dark
            style="text-transform: none !important; color: white" @click="open_media()">
            Add media
          </v-btn>
        </v-card-title>
        <v-card-subtitle>
          Add images to use them in your itinerary
        </v-card-subtitle>
        <v-card-text>
          <v-alert elevation="2" outlined icon="mdi-information-outline" style="font-size: 12px">
            For the best results, we recommend an image size of 1440 x 700
            pixels.
          </v-alert>
        </v-card-text>
        <v-container>
          <v-row>
            <v-col v-for="item in get_supplier_product_media" :key="item.id" class="d-flex child-flex" cols="3">
              <v-img :src="item.product_media.url" :lazy-src="item.product_media.url" aspect-ratio="1"
                class="grey lighten-2 media-image" @mouseover="hoverimage = true" @mouseleave="hoverimage = false">
                <v-overlay absolute color="#000" v-if="hoverimage">
                  <v-btn icon class="mr-3" @click="edit_media(item)">
                    <v-icon>mdi-pen</v-icon>
                  </v-btn>
                  <v-btn icon @click="deleteimage(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-overlay>
              </v-img>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="50%" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="dialog = false" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialog_title }}</span>
          <v-spacer />
          <div class="card-header-items">
            <div class="media-button">
              <input type="file" ref="files" class="input-media" accept="image/png, image/gif, image/jpeg"
                v-on:change="addMedia($event)" multiple />
              <v-btn rounded color="#525a68" dark @click="$refs.files.click()">
                Upload Media
              </v-btn>
            </div>
          </div>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field v-model="media_search" label="Search"></v-text-field>
              </v-col>
              <v-col v-for="media in get_media_info" :key="media.id" class="d-flex child-flex" cols="2">
                <v-img :src="media.url" :lazy-src="media.url" aspect-ratio="1" class="grey lighten-2 media-image"
                  @click="selectimage(media)">
                  <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: [],
  data: () => ({
    payload: [],
    dialog_title: null,
    dialog: false,
    showcode: 0,
    mode: null,
    media_search: null,
    edit: null,
    hoverimage: false,
  }),
  async mounted() {
    await this.$store.dispatch(
      "supplier/fetch_supplier_product_media",
      this.get_supplier_setup.id
    );
  },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_setup: "supplier/get_supplier_setup",
      get_selected_company: "auth/get_selected_company",
      get_supplier_product_media: "supplier/get_supplier_product_media",
    }),
    get_media_info() {
      return this.$store.getters["resources/get_media_info"](this.media_search);
    },
  },
  methods: {
    open_media() {
      this.dialog_title = "Media library";
      this.dialog = true;
      this.mode = "add";
    },

    edit_media(media) {
      this.dialog_title = "Media library";
      this.dialog = true;
      this.mode = "edit";
      this.edit = media;
    },
    async addMedia(event) {
      if (event.target.files.length != 0) {
        let counter = 0;
        let formdata = new FormData();
        formdata.append("company_id", this.get_selected_company.id);
        for (let img of event.target.files) {
          formdata.append("media" + counter, img);
          counter++;
        }
        formdata.append("counter", counter);
        await this.$axios
          .post("api/resources/add_media", formdata)
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch(
                "resources/fetch_media_info",
                this.get_selected_company.id
              );
              this.dialog = true;
            }
          });
      }
    },
    async selectimage(media) {
      const selectif = this.get_supplier_product_media.filter((item) => {
        return item.media_id === media.id;
      });
      if (!selectif[0]) {
        if (this.mode === "add") {
          await this.$axios
            .post("api/suppliers/products/add_product_media", {
              supplier_prod_id: this.get_supplier_setup.id,
              media_id: media.id,
            })
            .then(({ data }) => {
              if (data.response) {
                this.$store.dispatch(
                  "supplier/set_supplier_product_media",
                  data.data
                );
                this.dialog = false;
              }
            });
        } else if (this.mode === "edit") {
          await this.$axios
            .patch(`api/suppliers/products/update_product_media/${this.edit.id}`, {
              supplier_prod_id: this.get_supplier_setup.id,
              media_id: media.id,
            })
            .then(({ data }) => {
              if (data.response) {
                this.$store.dispatch(
                  "supplier/set_supplier_product_media",
                  data.data
                );
              }
            });
        }
      }
      this.dialog = false;
    },
    async deleteimage(media) {
      await this.$axios
        .patch(`api/suppliers/products/delete_product_media/${media.id}`, {
          supplier_prod_id: media.supplier_prod_id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "supplier/set_supplier_product_media",
              data.data
            );
          }
        });
    },
  },
  watch: {},
};
</script>
<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}

.card .card-header-items {
  text-align: center;
}

.card .card-header-items .header-items {
  margin: 0px 0px;
}

.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}

.image-show-wrapper {
  width: 100%;
}

.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}

.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}

.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}

.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}

.tags-wrapper .tags .tag-action {
  margin: auto 0;
}

.media-button {
  text-align: center;
}

.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}

.dialog-close {
  margin-left: -75px;
  position: absolute;
}

@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }

  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
