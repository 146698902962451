<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Options</strong></small>
        </v-card-title>
        <v-card-subtitle>
          Add the ways this product can be sold here; e.g. Infant, Child, Adult
          or Single, Twin, Double or 1-5 Days
        </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="f.name" label="Add an Option *" outlined dense
                @keyup.enter="save_supplier_product_option()">
                <template slot="append">
                  <small class="mt-2">Press ENTER to save</small>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-list-item two-line v-for="(items, index) in get_supplier_product_option" :key="index"
            style="border-top: 1px solid gray; border-bottom: 1px solid gray" class="mt-2">
            <v-list-item-content>
              <v-list-item-title>
                <label style="font-size: 16px">
                  {{ items.name }}
                </label>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-row>
                <!-- <v-chip
                  small
                  class="mt-1"
                  style="cursor: pointer;"
                  @click="show_details_child(items)"
                  v-if="items.option_child.length > 0"
                >
                  {{ items.option_child.length }}
                </v-chip> -->
                <!-- <v-btn icon
                  @click="view_rate_details(items)"
                >
                  <v-icon>
                    mdi-eye
                  </v-icon>
                </v-btn> -->
                <v-btn icon>
                  <v-icon @click="open_rates(items)" color="grey lighten-1">mdi-currency-usd</v-icon>
                </v-btn>
                <v-menu left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <v-list-item @click="open_media(items)">
                      <v-list-item-title>
                        <v-icon class="mr-3">mdi-pen</v-icon>
                        <strong>Edit</strong>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item @click="add_child(items)">
                      <v-list-item-title> 
                        <v-icon
                          class="mr-3"
                        >mdi-plus</v-icon>
                        <strong>Add Child</strong>
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title @click="duplicate_option(items)" style="cursor: pointer;">
                        <v-icon class="mr-3">
                          mdi-content-duplicate
                        </v-icon>
                        <strong>Duplicate</strong>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="archive_supplier_product_option(items)">
                      <v-list-item-title>
                        <v-icon class="mr-3">
                          mdi-archive
                        </v-icon>
                        <strong>Archive</strong>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-row>
            </v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-row>
    <v-dialog v-model="dialog" persistent max-width="60%" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="close_modal" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ dialog_title }}</span>
          <v-spacer />
          <v-btn v-if="mode === 'rates' && rates === false" color="#588BAD" rounded class="pl-10 pr-10" dark
            style="text-transform: none !important; color: white" @click="rates = true">
            Create rate period
          </v-btn>
        </v-card-title>
        <v-card-subtitle v-if="mode === 'rates'">
          Create a rate period and enter the applicable rates
        </v-card-subtitle>
        <v-card-text>
          <div v-if="!rates && mode === 'rates'">
            <v-list-item two-line v-for="(items, index) in ratelist" :key="index"
              style="border-top: 1px solid gray; border-bottom: 1px solid gray" class="mt-2">
              <v-list-item-content>
                <v-list-item-title>
                  <label style="font-size: 16px">{{ items.name }}</label><br />
                  <label style="font-size: 16px">{{ items.start_date }} - {{ items.end_date }}
                  </label>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-row>
                  <v-menu left>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on">
                        <v-icon>mdi-dots-vertical</v-icon>
                      </v-btn>
                    </template>

                    <v-list>
                      <!-- <v-list-item @click="edit_ratename(items)">
                        <v-list-item-title>
                          <v-icon
                            class="mr-3"
                          >
                            mdi-pen
                          </v-icon>
                          <strong>Edit</strong>
                        </v-list-item-title>
                      </v-list-item> -->
                      <v-list-item @click="duplicate_rates(items)">
                        <v-list-item-title>
                          <v-icon class="mr-3">
                            mdi-content-duplicate
                          </v-icon>
                          <strong>Duplicate</strong>
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="archive_rates(items)">
                        <v-list-item-title>
                          <v-icon class="mr-3">
                            mdi-archive
                          </v-icon>
                          <strong>Archive</strong>
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-row>
              </v-list-item-action>
            </v-list-item>
          </div>
          <v-row v-if="mode === 'edit'">
            <v-col cols="12">
              <v-text-field v-model="f.name" label="Add a Option *" outlined dense>
              </v-text-field>
              <v-card v-if="dialog_title === 'Edit option name'" elevation="0">
                <v-card-text>
                  <v-hover v-slot="{ hover }">
                    <v-card class="mx-auto" color="grey lighten-4" max-width="600">
                      <v-img
                        :src="image === null ? 'https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg' : image"
                        :aspect-ratio="16 / 9">
                      </v-img>
                      <v-fade-transition>
                        <v-overlay v-if="hover" absolute color="#036358">
                          <v-card elevation="0" color="transparent">
                            <v-card-title class="justify-center">
                              <v-row align="center" justify="center">
                                <v-btn icon style="cursor: pointer;" @click="insert_image(edit)">
                                  <v-icon color="black">mdi-pen</v-icon>
                                </v-btn>
                                <v-btn class="ml-1" style="cursor: pointer;" icon @click="remove_image(edit)">
                                  <v-icon color="black">mdi-delete</v-icon>
                                </v-btn>
                              </v-row>
                            </v-card-title>
                            <v-card-text v-if="has_to_upload_image">
                              <v-file-input show-size truncate-length="15" v-model="image_to_upload"
                                style="width: 250px;" outlined label="click here to change"></v-file-input>
                            </v-card-text>
                          </v-card>
                        </v-overlay>
                      </v-fade-transition>
                    </v-card>
                  </v-hover>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="mode === 'edit rates'">
            <v-col cols="12">
              <v-text-field v-model="f.name" label="Rates name" outlined dense>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="rates">
            <v-col cols="12">
              <v-text-field dense outlined label="Name *" v-model="r.name">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="date" dense outlined label="Start Date *" v-model="r.start_date">
              </v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field type="date" dense outlined label="End Date *" v-model="r.end_date">
              </v-text-field>
            </v-col>
          </v-row>
          <span v-if="rates" class="text-h5">Enter Rates for:</span>
          <div v-if="rates">
            <v-row v-for="(item, index) in drates" :key="item.name">
              <v-col cols="4">
                <v-text-field v-model="item.day" dense outlined label="Day" readonly>
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="item.net" type="number" dense outlined label="Net *"
                  @keyup="computerates(item, index)">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="item.markup" type="number" dense outlined label="Markup % *"
                  @keyup="computerates(item, index)">
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="item.gross" type="number" dense outlined label="Gross *" readonly>
                  {{ item.net * item.markup }}
                </v-text-field>
              </v-col>
              <v-col cols="2">
                <v-text-field v-model="item.commission" type="number" dense outlined label="Commission *" readonly>
                </v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn v-if="mode === 'edit'" color="#588BAD" dark rounded @click="update_supplier_product_option">
            Update
          </v-btn>
          <v-btn v-if="mode === 'edit rates'" color="#588BAD" dark rounded @click="update_supplier_product_option">
            Update
          </v-btn>
          <v-btn v-if="rates" color="#588BAD" dark rounded @click="save_supplier_product_rate()">
            Save
          </v-btn>
          <v-btn v-if="rates" color="#588BAD" dark rounded @click="rates = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="view_rate_dialog" persistent width="720">
      <v-card>
        <v-card-title>
          View Rates for {{ get_selected_service.name }}
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-group v-for="(item, index) in get_rates_view" :key="index" no-action>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ item.start_date }} - {{ item.end_date }}</v-list-item-title>
                  <!-- {{ get_edit_rates }} -->
                </v-list-item-content>
              </template>
              <v-data-table :headers="header_rates_child" :items="item.supplierrates" :expanded.sync="expandedchild"
                single-expand>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.day }}
                    </td>
                    <td>
                      {{ item.net }}
                    </td>
                    <td>
                      {{ item.gross }}
                    </td>
                    <td>
                      {{ item.markup }}
                    </td>
                    <td>
                      {{ item.commission }}
                    </td>
                    <td>
                      <v-btn icon @click="edit_rate(item)">
                        <v-icon small>mdi-pen</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-list-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="view_rate_dialog = false">
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="edit_rate_dialog" persistent width="560">
      <v-card>
        <v-card-title>
          <small>{{ get_edit_rates.day }}</small>
          <v-spacer />
          <v-icon @click="close_edit_rate_dialog()">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="edit_rate_model.net" label="Net" :hint="`Original Net: ${get_edit_rates.net}`" outlined
            dense type="number" @keyup="computeeditrates(edit_rate_model)">
          </v-text-field>
          <v-text-field v-model="edit_rate_model.markup" label="Markup %"
            :hint="`Original Markup: ${get_edit_rates.markup}`" outlined dense type="number"
            @keyup="computeeditrates(edit_rate_model)">
          </v-text-field>
          <v-text-field v-model="edit_rate_model.gross" label="Gross" :hint="`Original Gross ${get_edit_rates.gross}`"
            outlined dense readonly type="number">
          </v-text-field>
          <v-text-field v-model="edit_rate_model.commission" label="Commission"
            :hint="`Original Commission ${get_edit_rates.commission}%`" outlined dense readonly type="number">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn text @click="close_edit_rate_dialog()">
            <v-icon class="mr-1">
              mdi-close
            </v-icon>
            cancel
          </v-btn>
          <v-btn class="pl-10 pr-10" color="primary" @click="save_edited_data()">
            <v-icon class="mr-2">
              mdi-content-save
            </v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="child_data_dialog" persistent max-width="60%" overlay-color="#f4f4f5" overlay-opacity="1">
      <v-btn fab @click="child_data_dialog = false; child_data_model = null" class="dialog-close">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <v-card-title>
        {{ get_add_option_children_parent_data.name }}
        <v-spacer />
        <v-icon @click="child_data_dialog = false; child_data_model = null">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-subtitle>
        Add 3rd party supplier
      </v-card-subtitle>
      <v-card-text>
        <v-text-field dense outlined v-model="child_data_model"
          :placeholder="`add a child option for ${get_add_option_children_parent_data.name}`"
          @keyup.enter="add_child_option()">
          <template slot="append">
            <small class="mt-2">Press ENTER to add</small>
          </template>
        </v-text-field>
      </v-card-text>
    </v-dialog>
    <v-dialog v-model="show_child_data_dialog" persistent max-width="60%" overlay-color="#f4f4f5">
      <v-card>
        <v-card-title>
          {{ get_child_data_option.name }}
          <v-spacer />
          <v-icon @click="show_child_data_dialog = false">
            mdi-close
          </v-icon>
        </v-card-title>
        <v-card-subtitle>
          Show 3rd party supplier
        </v-card-subtitle>
        <v-card-text>
          <v-text-field placeholder="Search here..." outlined dense prepend-inner-icon="mdi-magnify"
            v-model="search_child_data">
          </v-text-field>
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="(item, i) in get_child_item_data" :key="i">
              <v-expansion-panel-header>{{ item.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card elevation="0">
                  <v-card-title>
                    Rate periods - {{ item.name }}
                    <v-spacer />
                    <v-btn color="primary" rounded small class="pr-10 pl-10"
                      @click="show_dialog_rate_period_child(item)">
                      create rate period
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle>
                    create a rate period and enter the applicable rates
                  </v-card-subtitle>
                  <v-card-text>
                    <!-- {{ item.supplier_option_child_rate }} -->
                    <v-expansion-panels accordion>
                      <v-expansion-panel v-for="(item_child, index_child) in item.supplier_option_child_rate"
                        :key="index_child">
                        <v-expansion-panel-header>{{ item_child.name }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-data-table :headers="header_rates_child"
                            :items="item_child.supplier_option_child_rate_days">
                            <template v-slot:item="{ item }">
                              <tr>
                                <td>
                                  {{ item.day }}
                                </td>
                                <td>
                                  {{ item.net }}
                                </td>
                                <td>
                                  {{ item.gross }}
                                </td>
                                <td>
                                  {{ item.markup }}
                                </td>
                                <td>
                                  {{ item.commission }}
                                </td>
                                <td>
                                  <!-- <v-btn
                                    icon
                                    @click="edit_rate(item)"
                                  >
                                    <v-icon
                                      small
                                    >mdi-pen</v-icon>
                                  </v-btn> -->
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
    </v-dialog>
    <PORatePeriods :value="rate_period_child" @close_child="close_child" @close_deep="close_deep" />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import PORatePeriods from './childcomponent/POrateperiods.vue'
export default {
  components: {
    PORatePeriods
  },
  props: [],
  data: () => ({
    dialog_title: null,
    dialog: false,
    edit: null,
    mode: null,
    rates: false,
    ratelist: null,
    image: null,
    has_to_upload_image: false,
    icons: ['mdi-rewind', 'mdi-play', 'mdi-fast-forward'],
    selectoption: null,
    image_to_upload: null,
    r: {
      name: null,
      start_date: null,
      end_date: null,
    },
    drates: [
      {
        day: "Monday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Tuesday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Wednesday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Thursday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Friday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Saturday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
      {
        day: "Sunday",
        net: 0,
        markup: 0,
        gross: 0,
        commission: 0,
      },
    ],
    f: {
      name: null,
    },
    showcode: 0,
    view_rate_dialog: false,
    expandedchild: [],
    header_rates_child: [
      {
        text: 'Day',
        sortable: false
      },
      {
        text: 'Net',
        sortable: false
      },
      {
        text: 'Gross',
        sortable: false
      },
      {
        text: 'Markup',
        sortable: false
      },
      {
        text: 'Commission',
        sortable: false
      },
      {
        text: '',
        sortable: false
      }
    ],
    edit_rate_dialog: false,
    edit_rate_model: {
      net: 0,
      markup: 0,
      gross: 0,
      commission: 0
    },
    child_data_dialog: false,
    child_data_model: null,
    show_child_data_dialog: false,
    search_child_data: null,
    rate_period_child: false
  }),
  async mounted() {
    await this.$store.dispatch(
      "supplier/fetch_supplier_product_option",
      this.get_supplier_product_setup.id
    );
  },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_product_setup: "supplier/get_supplier_product_setup",
      get_supplier_product_option: "supplier/get_supplier_product_option",
      get_supplier_product_options_edit: 'supplier/get_supplier_product_options_edit',
      get_rates_view: 'supplier/get_rates_view',
      get_selected_service: 'supplier/get_selected_service',
      get_edit_rates: 'supplier/get_edit_rates',
      get_response: 'supplier/get_response',
      get_user: 'auth/get_user',
      get_add_option_children_parent_data: 'supplier/get_add_option_children_parent_data',
      get_child_data_option: 'supplier/get_child_data'
    }),
    get_child_item_data() {
      return this.$store.getters['supplier/get_child_item_data'](this.search_child_data)
    }
  },
  methods: {
    showSnackBar(message) {
      this.$store.commit("auth/setMessage",
        { show: true, message: message },
        { root: 1 })
    },
    close_edit_rate_dialog() {
      this.edit_rate_dialog = false
      this.edit_rate_model.net = 0
      this.edit_rate_model.markup = 0
      this.edit_rate_model.gross = 0
      this.edit_rate_model.commission = 0
    },
    computerates(item, index) {
      const a = item.net * (item.markup / 100);
      const gross = Number(item.net) + Number(a);
      this.drates[index].gross = Number(gross);
      this.drates[index].commission = Number(item.markup / gross);
    },
    computeeditrates(item) {
      const a = item.net * (item.markup / 100);
      const gross = Number(item.net) + Number(a);
      this.edit_rate_model.gross = Number(gross);
      this.edit_rate_model.commission = Number(item.markup / gross);
    },
    async duplicate_option(data) {
      await this.$store.dispatch('supplier/duplicate_product_options', data)
      await this.$store.dispatch(
        "supplier/fetch_supplier_product_option",
        this.get_supplier_product_setup.id
      );
    },

    open_media(item) {
      this.dialog_title = `Edit option name`;
      this.dialog = true;
      this.f.name = item.name;
      this.edit = item;
      this.mode = "edit";
      this.rates = false;
      this.image = item.image
    },

    edit_ratename(item) {
      this.dialog_title = `Edit Rates name`;
      this.dialog = true;
      this.f.name = item.name;
      this.edit = item;
      this.mode = "edit rates";
      this.rates = false;
    },

    close_modal() {
      this.dialog_title = null;
      this.dialog = false;
      this.f.name = null;
      this.edit = null;
      this.mode = null;
      this.rates = false;
    },

    async open_rates(item) {
      this.dialog_title = `Rate periods - ${item.name}`;
      this.dialog = true;
      this.mode = "rates";
      this.selectoption = item;

      await this.$axios
        .get(`api/suppliers/products/get_product_option_rates/${item.id}`)
        .then(({ data }) => {
          if (data.response) {
            this.ratelist = data.data;
          }
        });
    },
    async save_supplier_product_rate() {
      await this.$axios
        .post("api/suppliers/products/add_product_option_rates", {
          sup_prod_option_id: this.selectoption.id,
          name: this.r.name,
          start_date: this.r.start_date,
          end_date: this.r.end_date,
          drate: this.drates,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch("supplier/set_supplier_rates", data.data);
          }
        });
      await this.$axios
        .get(
          `api/suppliers/products/get_product_option_rates/${this.selectoption.id}`
        )
        .then(({ data }) => {
          if (data.response) {
            this.ratelist = data.data;
          }
        });
      this.rates = false;
    },

    async save_supplier_product_option() {
      const selectif = this.get_supplier_product_option.filter((item) => {
        return item.name === this.f.name;
      });
      if (!selectif[0]) {
        await this.$axios
          .post("api/suppliers/products/add_product_option", {
            name: this.f.name,
            supplier_prod_id: this.get_supplier_product_setup.id,
          })
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch(
                "supplier/set_supplier_product_option",
                data.data
              );
            }
          });
      }
      this.f.name = null;
    },

    async update_supplier_product_option() {
      // console.log('edit', this.edit)
      const selectif = this.get_supplier_product_option.filter((item) => {
        return item.name === this.f.name;
      });
      if (!selectif[0]) {
        await this.$axios
          .patch(`api/suppliers/products/update_product_option/${this.edit.id}`, {
            name: this.f.name,
            supplier_prod_id: this.get_supplier_product_setup.id,
          })
          .then(({ data }) => {
            if (data.response) {
              this.$store.dispatch(
                "supplier/set_supplier_product_option",
                data.data
              );
            }
          });
      }
      this.f.name = null;
      this.dialog = false;
    },

    async archive_supplier_product_option(e) {
      await this.$axios
        .patch(`api/suppliers/products/delete_product_option/${e.id}`, {
          name: e.name,
          supplier_prod_id: e.supplier_prod_id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "supplier/set_supplier_product_option",
              data.data
            );
          }
        });
      this.f.name = null;
    },
    async archive_rates(e) {
      await this.$axios
        .patch(`api/suppliers/products/delete_product_option_rates/${e.id}`, e)
        .then(({ data }) => {
          if (data.response) {
            this.ratelist = data.data;
          }
        });
      this.f.name = null;
    },
    async duplicate_rates(e) {
      await this.$axios
        .patch(`api/suppliers/products/duplicate_product_option_rates/${e.id}`, e)
        .then(({ data }) => {
          if (data.response) {
            this.ratelist = data.data;
          }
        });
    },
    insert_image(data) {
      this.$store.dispatch('supplier/set_supplier_product_options_edit', data)
      this.has_to_upload_image = true
    },
    remove_image(item) {
      item.image = null
      this.$store.dispatch('supplier/set_empty_supplier_product_options_edit', item)
      this.close_modal()
    },
    async view_rate_details(item) {
      await this.$store.dispatch('supplier/fetch_rates_view', item)
      this.view_rate_dialog = true
    },
    edit_rate(data) {
      this.$store.dispatch('supplier/set_edit_rates', data)
      this.edit_rate_dialog = true
    },
    async save_edited_data() {
      let tp = {
        id: this.get_edit_rates.id,
        net: this.edit_rate_model.net,
        gross: this.edit_rate_model.gross,
        commission: this.edit_rate_model.commission,
        markup: this.edit_rate_model.markup,
        updated_by: this.get_user.id
      }
      this.$store.dispatch('supplier/update_rates', tp)
      if (!this.get_response.response) {
        this.showSnackBar(this.get_response.message)
      } else {
        this.showSnackBar(this.get_response.message)
        this.close_edit_rate_dialog()
        this.$store.dispatch('supplier/fetch_rates_view', this.get_selected_service)
      }
    },
    add_child(data) {
      this.$store.dispatch('supplier/set_add_option_children_parent_data', data)
      this.child_data_dialog = true
    },
    async add_child_option() {
      let tp = {
        name: this.child_data_model,
        spo_id: this.get_add_option_children_parent_data.id
      }
      this.$store.dispatch('supplier/post_product_option_children', tp)
      if (!this.get_response.response) {
        this.get_response.data.name.map(q => {
          this.showSnackBar(q)
        })
      } else {
        await this.$store.dispatch(
          "supplier/fetch_supplier_product_option",
          this.get_supplier_product_setup.id
        );
        this.child_data_dialog = false
        this.child_data_model = null
      }
    },
    show_details_child(item) {
      this.$store.dispatch('supplier/set_child_data', item)
      this.show_child_data_dialog = true
    },
    show_dialog_rate_period_child(item) {
      this.rate_period_child = true
      this.$store.dispatch('supplier/set_rate_period_child', item)
    },
    close_child() {
      this.rate_period_child = false
    },
    async close_deep() {
      this.close_child()
      this.show_child_data_dialog = false
      await this.$store.dispatch(
        "supplier/fetch_supplier_product_option",
        this.get_supplier_product_setup.id
      );
    }
  },
  watch: {
    async image_to_upload(newVal) {
      if (newVal !== null) {
        await this.$store.dispatch('supplier/update_image_product_options', newVal)
        await this.$store.dispatch(
          "supplier/fetch_supplier_product_option",
          this.get_supplier_product_setup.id
        );
        this.close_modal()
      }
    }
  },
};
</script>
<style scoped>
.card {
  max-width: 100%;
  background-color: white;
  margin: 25px 15px;
  padding: 25px 30px;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
}

.card .card-header {
  font-size: 24px;
  color: #343642;
  margin-bottom: 15px;
}

.card .card-header-items {
  text-align: center;
}

.card .card-header-items .header-items {
  margin: 0px 0px;
}

.media-image:hover {
  cursor: pointer;
  box-shadow: 0px 1px 5px 5px rgba(169, 169, 169, 0.6);
}

.image-show-wrapper {
  width: 100%;
}

.image-show-wrapper .image-show {
  max-width: 50%;
  margin: 0 auto;
}

.image-show-wrapper .image-show-name {
  font-size: 10px;
  text-align: center;
}

.tags-wrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.tags-wrapper .tags {
  max-height: 50px;
  display: flex;
  background-color: #dcdee0;
}

.tags-wrapper .tags .tag-name {
  margin: auto 0;
  padding: 12px;
  color: #343642;
  font-weight: bold;
  font-size: 16px;
  background: rgb(237, 238, 239);
}

.tags-wrapper .tags .tag-action {
  margin: auto 0;
}

.media-button {
  text-align: center;
}

.media-button .input-media {
  /* color: rgb(82, 90, 104); */
  display: none;
}

.dialog-close {
  margin-left: -75px;
  position: absolute;
}

@media only screen and (max-width: 959px) {
  .card .card-header {
    text-align: center;
  }

  .card .card-header-items .header-items {
    margin: 5px 5px 10px 5px;
  }
}
</style>
