<template>
  <v-container fluid style="background-color: #f4f4f5 !important">
    <v-row class="mt-5" justify="center" align="center">
      <v-card width="820" elevation="2">
        <v-card-title class="text-small">
          <small><strong>Amenities</strong></small>
          <!-- <v-spacer />
          <v-btn
            color="#588BAD"
            rounded
            small
            class="pl-10 pr-10"
            dark
            style="texttransform: none !important; color: white"
            @click="$router.push({ name: '/supplier-setup/create-product' })"
          >
            Select Amenities
          </v-btn> -->
        </v-card-title>
        <v-card-subtitle>
          Add features and facilities included with this product
        </v-card-subtitle>
        <v-card-text>
          <v-combobox hide-selected v-model="f.name" label="Add an amenities" hint="press ENTER to add"
            item-value="name" item-text="name" :items="get_supplier_product_amenity_type"
            @keyup.enter="save_supplier_amenity()"></v-combobox>
          <!-- {{get_supplier_amenities}} -->
          <v-list-item two-line v-for="(item, index) in get_supplier_product_amenity" :key="index"
            style="border-top: 1px solid gray; border-bottom: 1px solid gray" class="mt-2">
            <v-list-item-content>
              <v-list-item-title>
                <label style="font-size: 16px">{{ item.supplierproductamenitytype.name }}</label>
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn icon>
                <v-icon @click="archive_supplier_product_amenity(item)"
                  color="grey lighten-1">mdi-delete-outline</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  props: [],
  data: () => ({
    f: {
      name: null,
    },
  }),
  async mounted() {
    await this.$store.dispatch(
      "supplier/fetch_supplier_product_amenity",
      this.get_supplier_product_setup.id
    );
  },
  created() { },
  computed: {
    ...mapGetters({
      get_supplier_product_setup: "supplier/get_supplier_product_setup",
      get_supplier_product_amenity: "supplier/get_supplier_product_amenity",
      get_supplier_product_amenity_type:
        "supplier/get_supplier_product_amenity_type",
    }),
  },
  methods: {
    async save_supplier_amenity() {
      const selectif = this.get_supplier_product_amenity.filter((item) => {
        return item.supplierproductamenitytype.name === this.f.name;
      });
      if (!selectif[0]) {
        await this.$axios
          .post("api/suppliers/products/add_product_amenity", {
            name: this.f.name.name ? this.f.name.name : this.f.name,
            supplier_prod_id: this.get_supplier_product_setup.id,
          })
          .then(({ data }) => {
            if (data.response) {
              this.f.name = null;
              this.$store.dispatch(
                "supplier/set_supplier_product_amenity",
                data.data
              );
            }
          });
      }
      this.f.name = null;
    },
    async archive_supplier_product_amenity(e) {
      await this.$axios
        .patch(`api/suppliers/products/delete_product_amenity/${e.id}`, {
          supplier_prod_id: this.get_supplier_product_setup.id,
        })
        .then(({ data }) => {
          if (data.response) {
            this.$store.dispatch(
              "supplier/set_supplier_product_amenity",
              data.data
            );
          }
        });
    },
  },
  watch: {},
};
</script>
<style scoped lang="scss">
.header {
  max-width: 100%;
  background-color: #79a2bd;
  height: 50px;
  box-shadow: 0 2px 4px 0px rgb(0 0 0 / 20%);
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 50px;
}

.tabs {
  text-transform: none !important;
}
</style>
